import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../contexts/AppProvider";
import "./Header.css";

export default function Header() {
  const { sidebarRef, menuIconClick } = useContext(AppContext);
  return (
    <>
      <div ref={sidebarRef} className="sidebarGene">
        <h4 onClick={menuIconClick} className="sidebar-header">
          KyleGuard <span>&times;</span>
        </h4>
        <section className="creative top-bar-section">
          <div className="row">
            <ul className="right">
              <li className="">
                <Link to="/">KyleGuard</Link>
              </li>
              <li className="">
                <a href="#about">About us</a>
              </li>

              <li
                id="menu-item-2785"
                className="has-dropdown not-click  color-4"
              >
                <a href="#services" className="has-icon">
                  Services
                </a>

                <ul
                  className="sub-menu dropdown "
                  style={{
                    background:
                      "transparent url(__.html) no-repeat scroll right bottom;",
                  }}
                >
                  <li className="title back js-generated">
                    <h5>
                      <a href="javascript:void(0)">Back</a>
                    </h5>
                  </li>
                  <li className="parent-link hide-for-large-up">
                    <a className="parent-link js-generated" href="#">
                      Services
                    </a>
                  </li>

                  <li id="menu-item-2762" className="color-4">
                    <a href="#career" className="has-icon">
                      Careers
                    </a>
                  </li>
                </ul>
              </li>
              <li className="">
                <a href="#blog">Blog</a>
              </li>

              <li className="">
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
        </section>
      </div>
      <header className="l-header style-2 creative-layout">
        <div className="top-bar-container sticky">
          <nav className="top-bar" data-topbar>
            <ul className="title-area">
              <li className="name medium-3 large-3 columns">
                <Link
                  to="/"
                  rel="home"
                  title="KyleGuard Logo"
                  className="active"
                >
                  <h1>
                    <p className="logo-text">KyleGuard</p>

                    <img
                      className="logo-img"
                      style={{ height: "80px" }}
                      src="assets/images/logo.png"
                      alt="logo"
                    />
                  </h1>
                </Link>
              </li>
              <li className="medium-7 large-9 columns show-for-medium-up social_logo_top">
                <ul className="social-icons accent inline-list">
                  <li className="flickr">
                    <a href="http://www.flickr.com/webdevia">
                      <i className="fa fa-flickr"></i>
                    </a>
                  </li>
                  <li className="facebook">
                    <a href="https://www.facebook.com/webdevia">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li className="twitter">
                    <a href="https://twitter.com/webdevia">
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li className="vimeo">
                    <a href="https://vimeo.com/webdevia">
                      <i className="fa fa-vimeo-square"></i>
                    </a>
                  </li>
                </ul>
                <div className="contact-info">
                  <span>
                    <span className="wd-phone"></span>+124 548 698 254{" "}
                  </span>
                  <span>
                    <span className="wd-adress"></span>154 Av the good,
                    Casablanca{" "}
                  </span>
                </div>
              </li>
              <li className="medium-2 toggle-topbar menu-icon">
                <a href="#" onClick={(e) => menuIconClick()}>
                  <span>Menu</span>
                </a>
              </li>
            </ul>
            <section className="creative top-bar-section">
              <div className="row">
                <ul className="right">
                  <li className="">
                    <Link to="/">KyleGuard</Link>
                  </li>
                  <li className="">
                    <a href="#about">About us</a>
                  </li>

                  <li
                    id="menu-item-2785"
                    className="has-dropdown not-click  color-4"
                  >
                    <a href="#services" className="has-icon">
                      Services
                    </a>

                    <ul
                      className="sub-menu dropdown "
                      style={{
                        background:
                          "transparent url(__.html) no-repeat scroll right bottom;",
                      }}
                    >
                      <li className="title back js-generated">
                        <h5>
                          <a href="javascript:void(0)">Back</a>
                        </h5>
                      </li>
                      <li className="parent-link hide-for-large-up">
                        <a className="parent-link js-generated" href="#">
                          Services
                        </a>
                      </li>

                      <li id="menu-item-2762" className="color-4">
                        <a href="#career" className="has-icon">
                          Careers
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="">
                    <a href="#blog">Blog</a>
                  </li>

                  <li className="">
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </section>
          </nav>
        </div>
      </header>
    </>
  );
}
