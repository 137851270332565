import React, { createContext, useEffect, useRef, useState } from "react";
import useLocalStorage from "../services/useLocalStorage";
import { apiDataPost, apiDataPostForm } from "../services/apiRepository";
import {} from "../services/api";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { getDataFromJSON } from "../services/json_parse";

export const AppContext = createContext();

export default function AppProvider({ children }) {
  const navigate = useNavigate();
  const sidebarRef = useRef();

  const menuIconClick = (e) => {
    sidebarRef.current.classList.toggle("active");
  };

  const values = {
    sidebarRef,
    menuIconClick,
  };

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
}
