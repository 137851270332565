import React from 'react'
import { useNavigate } from 'react-router-dom';

export const YoutubeContext = React.createContext();

export default function YoutubeProvider({children}) {
    const navigate = useNavigate();

    const clickAndChangeRoute =()=>{
        navigate('/contact');
    }

    const values = {clickAndChangeRoute}
  return (
    <YoutubeContext.Provider value={values}>
        {children}
    </YoutubeContext.Provider>
  )
}
