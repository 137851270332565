import React from "react";

export default function Services() {
  return (
    <>
      <div
        className="what-we-provide clearfix animation-parent"
        data-animation-delay="350"
      >
        <div className="clearfix row">
          <div className="large-12 section_container">
            <div className="what-we-provide-container">
              <ul className="featured-box row">
                <li
                  className="large-4 column animated"
                  data-animated="fadeInRight"
                >
                  <div className="featured-box-image">
                    <img
                      width="390"
                      height="260"
                      src="assets/images/service-1.jpg"
                      alt="service"
                    />
                  </div>
                  <div className="service-text">
                    <h4 className="box-header">Surveillance Cameras</h4>
                    <p>
                      Leaders in point to point cash movement. Our highly
                      trained armed transit service can provide a secure
                      logistics solution.
                    </p>
                  </div>
                </li>
                <li
                  className="large-4 column animated"
                  data-animated="fadeInRight"
                >
                  <div className="featured-box-image">
                    <img
                      width="390"
                      height="260"
                      src="assets/images/security.jpeg"
                      alt="service"
                    />
                  </div>
                  <div className="service-text">
                    <h4 className="box-header">Your Business</h4>
                    <p>
                      Intruder and fire alarms, Access Control Systems,
                      Investigations, Rapid response, guards, CCTV, and physical
                      barriers.
                    </p>
                  </div>
                </li>
                <li
                  className="large-4 column animated"
                  data-animated="fadeInRight"
                >
                  <div className="featured-box-image">
                    <img
                      width="390"
                      height="260"
                      alt="home-lock"
                      src="assets/images/security.jpeg"
                    />
                  </div>
                  <div className="service-text">
                    <h4 className="box-header">Home Security</h4>
                    <p>
                      Alarm systems, rapid response, gates and fencing, garage
                      doors, guards, Smart Home technology & guard dogs.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
