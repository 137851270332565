import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import Services from "../components/Services";
import Plans from "../components/Plans";
import Career from "../components/Career";
import Brouchure from "../components/Brouchure";
import About from "../components/About";

export default function WholeApp() {
  return (
    <div>
      <Header />
      <main id="l-main">
        <Banner />
        <Services />
        <Brouchure />
        <Plans />
        <Career />
        <About />
        <br />
        <br />
        <br />
      </main>
      <Footer />
    </div>
  );
}
