import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../contexts/AppProvider";

export default function Footer() {
  const { subscribeSubmitForm } = useContext(AppContext);
  return (
    <>
      <section
        className="l-footer-columns animation-parent"
        data-animation-delay="350"
      >
        <h3 className="hide">Footer</h3>
        <div className="row">
          <ul
            className="block large-4 columns animated"
            data-animated="fadeInRight"
          >
            <li>
              <div className="textwidget">
                <div className="footer-logo">
                  <Link to="/">
                    <img
                      style={{ borderRadius: "20px" }}
                      width="140"
                      src="assets/images/logo.png"
                      alt="logo"
                    />
                  </Link>
                </div>
              </div>
            </li>
          </ul>
          <ul
            className="block large-4 columns animated"
            data-animated="fadeInRight"
          >
            <li>
              <h2 className="block-title">Get in Touch</h2>
              <section className="block-block contact-details block-block-19 block-even clearfix">
                <ul className="contact-details-list">
                  <li>
                    <span>
                      <span>Phone:</span> +263 77 679 3890
                    </span>
                  </li>
                  <li>
                    <span>
                      <span>Fax:</span> +263 77 679 3890
                    </span>
                  </li>
                  <li>
                    <span>
                      <span>Email:</span>{" "}
                      <a href="mailto:contact@kyleguard.co.zw">
                        contact@kyleguard.co.zw
                      </a>
                    </span>
                  </li>
                  <li>
                    <span>
                      <span>Adress:</span> Zimabwe
                    </span>
                  </li>
                </ul>
              </section>
            </li>
          </ul>
          <ul
            className="block large-4 columns animated"
            data-animated="fadeInRight"
          >
            <li>
              <div className="textwidget">
                <h2 className="block-title">Newsletter</h2> Sign up your
                newsletter
                <div className="newsletter-div">
                  <input
                    type="text"
                    name="email"
                    value=""
                    size="20"
                    placeholder="EMAIL ADRESS"
                    className="email-adress"
                  />
                  <input
                    type="submit"
                    value="GO"
                    className="newslettersubmit"
                  />
                  <ul className="social-icons accent inline-list">
                    <li className="flickr">
                      <a href="http://www.flickr.com/kyleguard">
                        <i className="fa fa-flickr"></i>
                      </a>
                    </li>
                    <li className="facebook">
                      <a href="https://www.facebook.com/kyleguard">
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li className="twitter">
                      <a href="https://twitter.com/kyleguard">
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li className="vimeo">
                      <a href="https://vimeo.com/kyleguard">
                        <i className="fa fa-vimeo-square"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <footer className="l-footer">
        <div className="row">
          <div className="footer large-12">
            <div className="block">
              <div className="wd_footer_menu clearfix">
                <ul className="menu" id="menu-footer-menu">
                  <li className="menu-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="menu-item">
                    <a href="#about">About Us</a>
                  </li>
                  <li className="menu-item">
                    <a href="#blog">Blog</a>
                  </li>
                  <li className="menu-item">
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="copyright large-12 text-center">
            <p>
              &copy; {new Date().getFullYear()} KyleGuard Security Services All
              rights reserved. Powered by{" "}
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCkcXcEAd523K1-YLJcEguRA"
              >
                PikiDelivery
              </a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}
