import React from "react";
import { Link } from "react-router-dom";

export default function About() {
  return (
    <div id="about">
      <div className="row">
        <div className="hero-title">
          <h3 style={{ fontWeight: "400;" }}>
            We are the leader of the security and bodyguard services industry.
          </h3>
        </div>
      </div>
      <div
        className="row box-text-container animation-parent"
        data-animation-delay="350"
      >
        <div
          className="large-4 column box-text animated"
          data-animated="fadeInRight"
        >
          <h3>Our Vision</h3>
          <p>
            To engage in business of hotels, flats, restaurants, cafes, taverns,
            beer house, refreshment rooms and lodges, housekeepers, licensed
            victuallers, wine beer and spirit merchants, distillers, importers
            and manufacturers of aerated waters, mineral and artificial waters
            and other drinks, caterers for public amusement, proprietors of
            clubs, baths, dressing rooms, laundries and general maintenance of
            schools, school grounds swimming pools and places of amusement and
            any other business which can conveniently be carried on in
            connection therewith
          </p>
        </div>
        <div
          className="large-4 column box-text animated"
          data-animated="fadeInRight"
        >
          <h3>Our Mission</h3>
          <p>
            To carry on the trade of business of security guard services,
            private investigations, tracing together with any other business
            usually connected thereto.
          </p>
        </div>
        <div
          className="large-4 column box-text animated"
          data-animated="fadeInRight"
        >
          <h3>Our Vision</h3>
          <p>
            To purchase, take on lease, hire or acquire any movable or immovable
            property whatsoever, including mines, mining titles, mining rights,
            claims, together with all or any other trade or business usually
            carried on in connection therewith and To carry on the business of
            investors in public and private companies, businesses and corchin
            and any property together with all or any other trade or
          </p>
        </div>
      </div>
      <div className="hero-button animation-parent" data-animation-delay="350">
        <div className="hero-button-container row">
          <div className="large-8 column animated" data-animated="fadeInRight">
            <header className="">
              <h2>Hey! just relax we are here to protect you!</h2>
            </header>
          </div>
          <div className="large-4 column animated" data-animated="fadeInRight">
            <Link to="/contact" className="button button-primary">
              Get in Touch
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
