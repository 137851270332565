import React from "react";
import { Link } from "react-router-dom";

export default function Plans() {
  return (
    <div id="plan">
      <div
        className="our-packages clearfix animation-parent"
        data-animation-delay="350"
      >
        <div className="row">
          <div className="wd-section-title text-center">
            <div className="subtitle">Choose on of our packages</div>
            <h2>Our Packages</h2>
          </div>
          <div className="paricing-table-container">
            <ul
              className="pricing-table large-3 column animated"
              data-animated="fadeInRight"
            >
              <li className="title">
                <h4>Starter</h4>
                <p>package</p>
              </li>
              <li className="bullet-item">
                Overcome your unique security challenges
              </li>
              <li className="bullet-item">
                Obtain access to patrols when you need it, or when an alarm
                sounds
              </li>
              <li className="bullet-item">Pre-development/Fire Pre-Plans</li>
              <li className="bullet-item">
                Intelligence &amp; Information Services
              </li>
              <li className="bullet-item">Hospitality Security</li>
              <li className="bullet-item">Integrated Guarding</li>
              <li className="bullet-item">Remote Alarm Verification</li>
              <li className="bullet-item">24/7 Support</li>
              <li className="cta-button">
                <Link to="/contact" className="button download-btn">
                  <i className="fa fa-arrow-circle-o-down"></i>Choose this pack
                </Link>
              </li>
            </ul>
            <ul
              className="pricing-table large-3 column animated featured"
              data-animated="fadeInRight"
            >
              <li className="title">
                <h4>VIP</h4>
                <p>package</p>
              </li>
              <li className="bullet-item">
                Overcome your unique security challenges
              </li>
              <li className="bullet-item">
                Obtain access to patrols when you need it, or when an alarm
                sounds
              </li>
              <li className="bullet-item">Pre-development/Fire Pre-Plans</li>
              <li className="bullet-item">
                Intelligence &amp; Information Services
              </li>
              <li className="bullet-item">Hospitality Security</li>
              <li className="bullet-item">Integrated Guarding</li>
              <li className="bullet-item">Remote Alarm Verification</li>
              <li className="bullet-item">24/7 Support</li>
              <li className="cta-button">
                <Link to="/contact" className="button download-btn">
                  <i className="fa fa-arrow-circle-o-down"></i>Choose this pack
                </Link>
              </li>
            </ul>
            <ul
              className="pricing-table large-3 column animated"
              data-animated="fadeInRight"
            >
              <li className="title">
                <h4>Gold</h4>
                <p>package</p>
              </li>
              <li className="bullet-item">
                Overcome your unique security challenges
              </li>
              <li className="bullet-item">
                Obtain access to patrols when you need it, or when an alarm
                sounds
              </li>
              <li className="bullet-item">Pre-development/Fire Pre-Plans</li>
              <li className="bullet-item">
                Intelligence &amp; Information Services
              </li>
              <li className="bullet-item">Hospitality Security</li>
              <li className="bullet-item">Integrated Guarding</li>
              <li className="bullet-item">Remote Alarm Verification</li>
              <li className="bullet-item">24/7 Support</li>
              <li className="cta-button">
                <Link to="/contact" className="button download-btn">
                  <i className="fa fa-arrow-circle-o-down"></i>Choose this pack
                </Link>
              </li>
            </ul>
            <ul
              className="pricing-table large-3 column animated"
              data-animated="fadeInRight"
            >
              <li className="title">
                <h4>Premium</h4>
                <p>package</p>
              </li>
              <li className="bullet-item">
                Overcome your unique security challenges
              </li>
              <li className="bullet-item">
                Obtain access to patrols when you need it, or when an alarm
                sounds
              </li>
              <li className="bullet-item">Pre-development/Fire Pre-Plans</li>
              <li className="bullet-item">
                Intelligence &amp; Information Services
              </li>
              <li className="bullet-item">Hospitality Security</li>
              <li className="bullet-item">Integrated Guarding</li>
              <li className="bullet-item">Remote Alarm Verification</li>
              <li className="bullet-item">24/7 Support</li>
              <li className="cta-button">
                <Link to="/contact" className="button download-btn">
                  <i className="fa fa-arrow-circle-o-down"></i>Choose this pack
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
