import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div>
      <Header />
      <section className="page-header page_404">
        <div className="container">
          <div className="page-header_wrap">
            <div className="page-heading">
              <h1>Error 404</h1>
            </div>
            <ul className="coustom-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Error 404</li>
            </ul>
          </div>
        </div>

        <div className="dark-overlay"></div>
      </section>
      <section className="error_404 section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-5">
              <div className="error_text_m">
                <h2>
                  4<span>0</span>4
                </h2>
                <div className="background_icon">
                  <i className="fa fa-road" aria-hidden="true"></i>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-7">
              <div className="not_found_msg">
                <div className="error_icon">
                  {" "}
                  <i className="fa fa-smile-o" aria-hidden="true"></i>{" "}
                </div>
                <div className="error_msg_div">
                  <h3>
                    Oops, <span>Page Can't be Found</span>
                  </h3>
                  <p>
                    But I must explain to you how all this mistaken idea of
                    denouncing pleasure and praising pain was born and I will
                    give you a complete account of the system, and expound the
                    actual teachings of the great explorer of the truth,
                  </p>
                  <Link to="/" className="btn">
                    Back to Home{" "}
                    <span className="angle_arrow">
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </span>
                  </Link>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
