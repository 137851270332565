import React, { useState } from "react";
import swal from "sweetalert";
import { apiDataPost, apiDataPostForm } from "../services/apiRepository";
import { sendEmailApi } from "../services/api";

export default function Contacts() {
  const [inputs, setInputs] = useState({});
  const [sending, setSending] = useState(false);

  const contactMe = async (e) => {
    e.preventDefault();
    setSending(true);
    const formData = new FormData();
    formData.append("subject", inputs?.subject);
    formData.append("name", inputs?.name);
    formData.append("email", inputs?.email);
    formData.append("message", inputs?.message);
    var response = await apiDataPostForm(sendEmailApi, formData);
    if (response.status == 200) {
      setSending(false);
      swal("Success", response.message.toString(), "success");
    } else {
      swal("Warning", response.message.toString(), "warning");
      setSending(false);
    }
    setInputs({ ...inputs, name: "", subject: "", email: "", message: "" });
  };
  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };
  return (
    <>
      <main id="l-main">
        <div className="clearfix contact">
          <div className="clearfix row">
            <div className="large-4 column">
              <p>
                KyleGuard SECURITY SERVICE is established to pay any officer,
                servant or employee of the company a bonus, commission or share
                of profits whether by way of or in addition to his ordinary
                remuneration and to establish support or aid in the
                establishment or the support of Associations, institutions,
                Pension Funds and conveniences calculated to benefit employees,
                officers, ex-employees and ex-officers of the company or the
                wives, widows, families or dependents of such persons and to
                make grants or grant pensions and allowances and to subscribe to
                make donations to and otherwise assist any public, charitable.
              </p>
              <span className="vc_icon_element-icon entypo-icon entypo-icon-map"></span>
              <p>
                Monday – Friday: 08.00 – 17.00
                <br />
                Saturday: 09.00 – 12.00
              </p>
            </div>
            <div className="large-8 column">
              <div role="form">
                <div className="screen-reader-response"></div>
                <form
                  onSubmit={contactMe}
                  method="post"
                  className="contact-form"
                  novalidate="novalidate"
                >
                  <div className="large-6 columns">
                    <span className="form-control-wrap">
                      <input
                        type="text"
                        placeholder="Your Name"
                        onChange={handleChange}
                        className="form-control"
                        size="40"
                        value={inputs?.name}
                        name="name"
                      />
                    </span>
                    <p></p>
                    <p>
                      <span className="form-control-wrap">
                        <input
                          type="email"
                          placeholder="Email"
                          className="form-control"
                          onChange={handleChange}
                          size="40"
                          value={inputs?.email}
                          name="email"
                        />
                      </span>
                    </p>
                    <p>
                      <span className="form-control-wrap your-subject">
                        <input
                          type="text"
                          placeholder="Subject"
                          className="form-control"
                          onChange={handleChange}
                          size="40"
                          value={inputs?.subject}
                          name="subject"
                        />
                      </span>
                    </p>
                    <p>We will contact you within one business day</p>
                    <p></p>
                  </div>
                  <div className="large-6 columns">
                    <span className="form-control-wrap">
                      <textarea
                        placeholder="Your Message"
                        className="form-control"
                        onChange={handleChange}
                        value={inputs?.message}
                        rows="6"
                        cols="40"
                        name="message"
                      ></textarea>
                    </span>
                    <p></p>
                    <p className="right">
                      <input
                        disabled={sending}
                        type="submit"
                        className="form-submit"
                        value={
                          sending ? "Sending message please wait!!!!" : "Send"
                        }
                      />
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
