import React, { useContext } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { AppContext } from "../contexts/AppProvider";
import Contacts from "../components/Contacts";

export default function Contact() {
  const {
    profile,
    commentsHandleChange,
    commentsForm,
    commentsHandleSubmit,
    loading,
  } = useContext(AppContext);
  return (
    <div>
      <Header />
      <section className="titlebar">
        <div className="row">
          <div className="large-8 columns">
            <h2 className="title" id="page-title">
              Contact Us
            </h2>{" "}
          </div>
          <div className="large-4 columns">
            <ul className="breadcrumbs">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <strong> Contact Us</strong>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <Contacts />
      <Footer />
    </div>
  );
}
