import React, { useEffect } from "react";
import { YoutubeContext } from "../contexts/YoutubeProvider";

export default function Banner() {
  const { clickAndChangeRoute } = React.useContext(YoutubeContext);

  useEffect(() => {
    // var swiper = new window.Swiper('.swiper-container', {
    //   pagination: '.swiper-pagination',
    //   paginationClickable: true,
    //   nextButton: '.swiper-button-next',
    //   prevButton: '.swiper-button-prev',
    //   speed: 1200
    // });

    var swiper = new window.Swiper(".swiper-container", {
      cssMode: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
      },
      mousewheel: false,
      keyboard: true,
    });
  }, []);
  return (
    <>
      <div className="slider">
        <div className="swiper-container">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="slider-text">
                <h4 className="title">We provide</h4>
                <h2 className="subtitle">Close protection security guards</h2>
                <div className="text">
                  <p>
                    To insure the company's property, offices, employees and
                    directors against loss or damage of whatever nature and to
                    effect insurances upon the life of any director, officer,
                    employee or debtor of the company and pay the costs and
                    premiums thereof.
                  </p>
                </div>
              </div>
              <img src="assets/images/bodygard-slide.png" alt="slide" />
            </div>
            <div className="swiper-slide">
              <div className="slider-text">
                <h4 className="title">We provide</h4>
                <h2 className="subtitle">surveillance CAMERAS</h2>
                <div className="text">
                  <p>
                    Be true to yourself. Make each day a masterpiece. Help
                    others. Drink deeply from good books. Make friendship a fine
                    art. Build a shelter against a rainy day.
                  </p>
                </div>
              </div>
              <img src="assets/images/slider/slide-2.jpg" alt="slide" />
            </div>
          </div>

          <div className="swiper-pagination"></div>

          <div className="swiper-button-prev">
            <i className="fa fa-angle-left"></i>
          </div>
          <div className="swiper-button-next">
            <i className="fa fa-angle-right"></i>
          </div>

          <div className="swiper-scrollbar"></div>
        </div>
      </div>
    </>
  );
}
