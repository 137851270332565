import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import axios from "axios";
import { apihost } from "./services/api";
import WholeApp from "./pages/WholeApp";
import AppProvider from "./contexts/AppProvider";
import NotFound from "./pages/NotFound";
import YoutubeProvider from "./contexts/YoutubeProvider";
import Contact from "./pages/Contact";

axios.defaults.baseURL = apihost;
// axios.defaults.headers.post["content-type"] = "application/json";
// // axios.defaults.headers.post["content-type"] = "multipart/form-data: boundary=add-random-characters";
// axios.defaults.headers.post["Accept"] = "application/json";

axios.defaults.withCredentials = false;
axios.interceptors.request.use(function (config) {
  const auth = JSON.parse(localStorage.getItem("carsale-auth"));
  config.headers.Authorization = auth?.token ? `Bearer ${auth?.token}` : "";
  return config;
});

function App() {
  return (
    <Router>
      <YoutubeProvider>
        <AppProvider>
      <Routes>
          <Route exact path="/" element={<WholeApp />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="*" element={<NotFound />} />
      </Routes>
        </AppProvider>
        </YoutubeProvider>
    </Router>
  );
}

export default App;
