import React from "react";

export default function Brouchure() {
  return (
    <div>
      <div
        className="clearfix brochure animation-parent"
        data-animation-delay="350"
      >
        <div className="row">
          <div className="large-6 column brochure-image">
            <img src="assets/images/man-shadow.jpg" alt="man-shadow" />
          </div>
          <div className="large-6 column">
            <div
              className="text-box clearfix animated"
              data-animated="fadeInRight"
            >
              <div className="box-icon">
                <img alt="icon" src="assets/images/support.png" />
              </div>
              <h4>Access control, CCTV, intruder alarms</h4>
              <p></p>
            </div>
            <div
              className="text-box clearfix animated"
              data-animated="fadeInRight"
            >
              <div className="box-icon">
                <img alt="icon" src="assets/images/access.png" />
              </div>
              <h4>Risk management consultancy services</h4>
              <p></p>
            </div>
            <div
              className="text-box clearfix animated"
              data-animated="fadeInRight"
            >
              <div className="box-icon">
                <img alt="icon" src="assets/images/cam.png" />
              </div>
              <h4>Electronic monitoring</h4>
              <p></p>
            </div>
            <div
              className="text-box clearfix animated"
              data-animated="fadeInRight"
            >
              <div className="box-icon">
                <img alt="icon" src="assets/images/event.png" />
              </div>
              <h4>Managing ATMs on behalf of banks</h4>
              <p></p>
            </div>
          </div>
          {/* <div className="text-center animated" data-animated="fadeInRight">
                      <a href="#" className="button download-btn"><i className="fa fa-arrow-circle-o-down"></i>Download Brochure</a>
                  </div> */}
        </div>
      </div>
    </div>
  );
}
