import React from "react";
import { Link } from "react-router-dom";

export default function Career() {
  return (
    <div id="career">
      <div
        className="career-opportunities clearfix animation-parent"
        data-animation-delay="350"
      >
        <div className="large-7 column " style={{ height: "569px;" }}>
          <div className="large-6">
            {/* <h4>
              Premium Security Guards offers a wealth of career opportunities.
            </h4> */}
          </div>
        </div>
        <div className="large-5 column">
          <div>
            <h3>Career Opportunities</h3>
            <ul className="vacancyList">
              <li className="vacancy animated" data-animated="fadeInRight">
                <Link to="/contact">
                  <br />
                  <span className="job">
                    ATM Engineer &ndash; Day Shifts &ndash; Carlisle
                  </span>
                  <br />
                  <span className="salary">
                    <b>Salary:</b> $280 per month depending on experience
                  </span>
                  <br />
                  <span className="location">
                    <b>Location:</b> Harare, Zimbabwe
                  </span>
                  <br />
                  <i className="fa fa-arrow-circle-o-right"></i>
                  <br />
                </Link>
              </li>
              <li className="vacancy animated" data-animated="fadeInRight">
                <Link to="/contact">
                  <br />
                  <span className="job">Security Officer/P413/52400/1</span>
                  <br />
                  <span className="salary">
                    <b>Salary:</b> $300
                  </span>
                  <br />
                  <span className="location">
                    <b>Location:</b> Harare, Zimbabwe
                  </span>
                  <br />
                  <i className="fa fa-arrow-circle-o-right"></i>
                  <br />
                </Link>
              </li>
              <li className="vacancy animated" data-animated="fadeInRight">
                <Link to="/contact">
                  <br />
                  <span className="job">Secure Driver &ndash; Glasgow</span>
                  <br />
                  <span className="salary">
                    <b>Salary:</b> $230 per month
                  </span>
                  <br />
                  <span className="location">
                    <b>Location:</b> Harare, Zimbabwe
                  </span>
                  <br />
                  <i className="fa fa-arrow-circle-o-right"></i>
                  <br />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
